import React, { useEffect, useState } from "react";
import { Slide } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NetworkError = ({ setNoInternet, noInternet, action }) => {
  const handleClickOpen = () => {
    setNoInternet(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    else setNoInternet(false);
    // setNoInternet(false);
  };

  return (
    <div>
      <Dialog
        open={noInternet}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Tidak ada koneksi internet</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Periksa kembali koneksi internet Sobat, kemudian tekan tombol muat
            ulang!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              action();
              setNoInternet(false);
            }}
            variant="outlined"
          >
            <RefreshOutlinedIcon fontSize="small" />
            <span className="ps-1">Muat Ulang</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NetworkError;
