import React, { useState } from "react";
import mapPapua from "../images/map/mapPapua.webp";
import mapJawa from "../images/map/mapJawa.webp";
import mapKalimantan from "../images/map/mapKalimantan.webp";
import mapSulawesi from "../images/map/mapSulawesi.webp";
import mapSumatera from "../images/map/mapSumatera.webp";
import staticMap from "../images/map/staticMap.webp";

const IndonesianMap = ({ onClick, selectedArea, listWilayah }) => {
  const imageSources = {
    1: mapPapua,
    3: mapKalimantan,
    4: mapSulawesi,
    2: mapJawa,
    5: mapSumatera,
  };


  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          <div style={{ display: "grid" }}>
            <img
              style={{ maxHeight: "15vh" }}
              src={selectedArea.id ? imageSources[selectedArea.id] : staticMap}
            />
          </div>
          {listWilayah.map((item, index) => (
            <div key={index}>
              <div
                style={{
                  position: "absolute",
                  top: "25%",
                  left: "0%",
                  width: "70px",
                  height: "20px",
                  transform: "rotate(50deg)",
                  display: item.id_mst_wilayah === 5 ? "block" : "none",
                }}
                className="btnClr"
                onClick={() => onClick("5_SUMATERA")}
              ></div>
              <div
                style={{
                  position: "absolute",
                  top: "10%",
                  left: "30%",
                  width: "55px",
                  height: "40px",
                  display: item.id_mst_wilayah === 3 ? "block" : "none",
                }}
                className="btnClr"
                onClick={() => onClick("3_KALIMANTAN")}
              ></div>
              <div
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "50%",
                  width: "40px",
                  height: "24px",
                  transform: "rotate(-70deg)",
                  display: item.id_mst_wilayah === 4 ? "block" : "none",
                }}
                className="btnClr"
                onClick={() => onClick("4_SULAWESI")}
              ></div>
              <div
                style={{
                  position: "absolute",
                  top: "57%",
                  right: "0%",
                  width: "130px",
                  height: "35px",
                  transform: "rotate(-20deg)",
                  display: item.id_mst_wilayah === 1 ? "block" : "none",
                }}
                className="btnClr"
                onClick={() => onClick("1_INDONESIA TIMUR")}
              ></div>
              <div
                style={{
                  position: "absolute",
                  bottom: "10%",
                  left: "25%",
                  width: "50px",
                  height: "20px",
                  display: item.id_mst_wilayah === 2 ? "block" : "none",
                }}
                className="btnClr"
                onClick={() => onClick("2_JAWA-BALI")}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndonesianMap;
