import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import { Slide } from "@mui/material";
import "./Tipe.css";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import NetworkError from "../../components/NetworkError";
import BottomSheet from "../../components/BottomSheet";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import DialogError from "../../components/DialogError";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Varian = ({
  pickMerk,
  ambilVarian,
  pickTipe,
  setSlidedInVarian,
  slidedInVarian,
  setSlided,
  setSlidedInTahun,
  setSearchTipe,
  searchTipe,
  token,
}) => {
  const [listVarian, setListVarian] = useState([]);
  const [loadVarian, setLoadVarian] = useState(true);
  const [noInternet, setNoInternet] = useState(false);
  const [isError, setIsError] = useState(false)

  const handleChange = (event) => {
    setSearchTipe(event.target.value);
  };
  useEffect(() => {
    const delay = setTimeout(() => {
      setSlidedInVarian(true);
    }, 700);

    getVarian();
    return () => clearTimeout(delay);
  }, [pickMerk, slidedInVarian]);

  const getVarian = async () => {
    var params = new URLSearchParams();

    params.append("id_mst_motor_merk", pickMerk);
    params.append("tipe_unit", pickTipe);


    var request = {
      params: params,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios
      .get(baseUrl + "/produk/v4/produk_taksasi", request)
      .then((response) => {
        setListVarian(response.data.data);
        setLoadVarian(false);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setNoInternet(true);
        }
        console.log(error.message);
        setIsError(true)
      });
  };

  const nextStep = (item) => {
    setSlidedInVarian(false);
    setSlidedInTahun(true);

    setTimeout(() => {
      setSlided(true);
      ambilVarian(item.varian);
    }, 700);
  };
  return (
    <div>
      <div
        style={{
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
        }}
      >
        <div
          style={{
            overflowY: "scroll",
          }}
        >
          <Slide direction="up" in={slidedInVarian} timeout={700}>
            {loadVarian === true ? (
              <div
                style={{
                  backgroundColor: "#10494E",
                  maxHeight: "340px",
                  overflow: "hidden",
                  borderTopRightRadius: "40px",
                  borderTopLeftRadius: "40px",
                }}
              >
                {[...Array(12)].map((e, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        height: "30px",
                        borderRadius: "10px",
                        alignItems: "center",
                      }}
                      className=" mt-2 pt-2 px-3 mx-1"
                    >
                      <Skeleton
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          bgcolor: "#1B7A83",
                        }}
                        animation="wave"
                        variant="rectangular"
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    backgroundColor: "#B5DBDE",
                    height: "120px",
                    zIndex: 1,
                    marginBottom: "-18%",
                    borderTopRightRadius: "40px",
                    borderTopLeftRadius: "40px",
                    textAlign: "center",
                  }}
                >
                  <p
                    className="pt-2 d-flex"
                    style={{
                      maxWidth: "90%",
                      color: "#10494E",
                      fontWeight: "700",
                      maxHeight: "45px",
                      alignItems:"center"
                    }}
                  >
                    {listVarian[0].merk +' '+ pickTipe}
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#10494E",
                    borderTopRightRadius: "40px",
                    borderTopLeftRadius: "40px",
                    zIndex: 2,
                  }}
                >
                  <TextField
                    value={searchTipe}
                    onChange={handleChange}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#20929D",
                      width: "90%",
                    }}
                    className="my-3"
                    id="search-bar"
                    variant="outlined"
                    placeholder="Cari"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div
                    style={{
                      overflowY: "auto",
                      height: "35vh",
                      color: "white",
                    }}
                  >
                    <Stack spacing="0">
                      {listVarian.map((item, index) => (
                        <div className="px-3" key={index}>
                          <BottomSheet
                            item={item.varian}
                            searchTipe={searchTipe}
                            onClick={() => nextStep(item)}
                          />
                          <Divider className="custom-divider" />
                        </div>
                      ))}
                    </Stack>
                  </div>
                </div>
              </div>
            )}
          </Slide>
        </div>
      </div>
      <NetworkError
        setNoInternet={setNoInternet}
        noInternet={noInternet}
        action={getVarian}
      />
      <DialogError isOpen={listVarian.length === 0 && isError ? true : false} />

    </div>
  );
};

export default Varian;
