import React from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";


const YoutubeEmbed = ({ embedId }) => {
  const onEnd = (event) => {
    // Call the `stopVideo` function to stop the player from playing
    event.target.stopVideo();
  };
  const videoOptions = {
    playerVars: {
      preventRedirect: 1,
      autoplay: 1,
      controls: 1,
      rel: 1,
      showinfo: 0,
      mute: 0,
      loop: 1,
      fullscreen: 1,
    },
    width: "100%",
    height: "200px",
  };

  return (
    <div>
      <YouTube videoId={embedId} opts={videoOptions} onEnd={onEnd} />
    </div>
  );
};

export default YoutubeEmbed;
