import React, { useState, useCallback, setState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import "./Awal.css";
import { Checkbox, Box, Button, FormControlLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MobileStepper from "@mui/material/MobileStepper";
import Drawer from "@mui/material/Drawer";
import Stepper from "../../components/Stepper";
import Awal1 from "../../images/awal1.webp";
import Awal2 from "../../images/awal2.webp";
import Awal3 from "../../images/awal3.webp";
import Navbar from "../../components/Navbar";

const Awal = ({ mulaiTaksasi, setZoomedInAwal, setZoomed }) => {
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [changeVisible, setChangeVisible] = useState(false);
  const [text, setText] = useState(
    " Mau tau kondisi motor dan perkiraan harganya?"
  );
  const [image, setImage] = useState(Awal1);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 3;

  const handleStepChange = (step) => {
    setActiveStep(step);
    if (step === 1) {
      setText("Mudah, bisa dilakukan dimana & kapan saja");
      setImage(Awal2);
    } else if (step === 2) {
      setText("Taksasi bakal kasih tau kisaran harga motormu");
      setImage(Awal3);
    }
  };
  const handleStepBack = (step) => {
    setActiveStep(step);
    if (step === 0) {
      setText("Mau tau kondisi motor dan perkiraan harganya?");
      setImage(Awal1);
    } else if (step === 1) {
      setText("Mudah, bisa dilakukan dimana & kapan saja");
      setImage(Awal2);
    } else if (step === 2) {
      setText("Taksasi bakal kasih tau kisaran harga motormu");
      setImage(Awal3);
    }
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const nextStep = () => {
    setChangeVisible(true);
    setTimeout(() => {
      setZoomed(true);
      mulaiTaksasi();
    }, 1600);
  };

  return (
    <div
      className="d-flex px-3 pb-3"
      style={{
        flexDirection: "column",
        justifyContent: "space-around",
        backgroundColor: "white",
        minHeight: "100vh",
      }}
    >
      <Stepper
        className={changeVisible ? "fadeout" : ""}
        step={activeStep}
        textProps={text}
        image={image}
        onClick={() => handleStepBack(activeStep - 1)}
      />

      <div
        className={
          changeVisible
            ? "fadeout d-flex justify-content-center"
            : "d-flex justify-content-center"
        }
        style={{ height: "10vh", marginTop: "8%" }}
      >
        <Checkbox
          size="small"
          style={{ color: "#20929D" }}
          onChange={handleChange}
          checked={checked}
        />
        <div
          className="d-flex align-items-center "
          style={{ fontSize: "12px", fontFamily: "Poppins" }}
        >
          <span style={{ textAlign: "left" }}>
            Dengan ini Sobat menyetujui
            <span style={{ color: "#20929D" }} onClick={handleDrawerOpen}>
              {" syarat & ketentuan"}
            </span>
          </span>
        </div>
      </div>

      <div className={"columnBox"}>
        {!changeVisible ? (
          <Button
            disabled={checked ? false : true}
            size="small"
            className={changeVisible ? "fadeout" : ""}
            style={!checked ? { color: "#BABEC1" } : { color: "#1B7A83" }}
            onClick={() => {
              nextStep();
            }}
          >
            <span style={{ fontFamily: "Poppins" }}>Lewati</span>
          </Button>
        ) : (
          <Button>
            <div className="scaleButton"></div>
          </Button>
        )}

        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="dots"
          style={{ display: "flex", justifyContent: "center" }}
          activeStep={activeStep}
          className={changeVisible ? "fadeout dot" : " dot"}
          onChange={handleStepChange}
        />

        {activeStep === maxSteps - 1 ? (
          <Button
            className={changeVisible ? "fadeout" : ""}
            disabled={checked ? false : true}
            size="small"
            style={{ borderRadius: "50px" }}
            onClick={() => {
              nextStep();
            }}
          >
            <img
              style={
                checked ? { width: "80%" } : { width: "80%", opacity: "0.3" }
              }
              src={require("../../images/next.png")}
            />
          </Button>
        ) : (
          <Button
            className={changeVisible ? "fadeout" : ""}
            disabled={checked ? false : true}
            size="small"
            style={{ borderRadius: "50px" }}
            onClick={() => {
              handleStepChange(activeStep + 1);
            }}
          >
            <img
              style={
                checked ? { width: "80%" } : { width: "80%", opacity: "0.3" }
              }
              src={require("../../images/next.png")}
            />
          </Button>
        )}
      </div>

      <Drawer variant="persistent" open={open} anchor="bottom">
        <div style={{ color: "black" }} className="p-2">
          <div className="d-flex justify-content-end">
            <Button onClick={handleDrawerClose}>X</Button>
          </div>
          <center>
            <b>Syarat & Ketentuan Taksasi</b>
          </center>
          <div
            style={{
              textAlign: "justify",
              height: "30vh",
              overflowY: "scroll",
              fontFamily: "poppins",
              fontSize: "14px",
              color: "#717375",
            }}
            className="mt-2 mx-3"
          >
            <p>
              1. Seluruh informasi dan data yang disediakan dalam fitur taksasi
              adalah bersifat taksiran dari nilai atau harga dari suatu objek
              tertentu. Dengan demikian tidak dapat dianggap maupun ditafsirkan
              sebagai suatu hal informasi yang bersifat pasti.
            </p>
            <p>
              2. Pada dasarnya SiMotor tidak dapat digugat maupun dituntut atas
              segala pernyataan, ketidaktepatan, kekeliruan atau kekurangan
              dalam setiap hasil informasi yang dilakukan didalam fitur taksasi.
            </p>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Awal;
