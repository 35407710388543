// axiosConfig.js
import axios from 'axios';

const instance = axios.create();


const setupAxiosInterceptors = () => {
  console.log('asdsd')
  const interceptor = instance.interceptors.response.use(
    (response) => console.log('asd'),
    (error) => {
      // Handle errors globally, e.g., show a dialog
      // You can also dispatch an action, log the error, etc.
      console.error('Axios request failed:', error);
      return Promise.reject(error);
    }
  );

  return () => {
    // Eject the interceptor on cleanup
    instance.interceptors.response.eject(interceptor);
  };
};

export { instance, setupAxiosInterceptors };
