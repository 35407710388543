import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import "./Merk.css";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NetworkError from "../../components/NetworkError";
import DialogError from "../../components/DialogError";
const baseUrl = process.env.REACT_APP_BASE_URL;
const baseImage = process.env.REACT_APP_BASE_URL_IMAGE;

const Merk = ({
  loadMerk,
  setLoadMerk,
  pickMerk,
  ambilTahun,
  pickTahun,
  ambilMerk,
  ambilVarian,
  setSlidedInTipe,
  setSlidedInVarian,
  setSlidedInTahun,
  setSlidedInWilayah,
  tempoMerk,
  setTempoMerk,
  setTempoTahun,
  setToken,
  token,
  setIsTokenSame,
}) => {
  // use state
  const [listMerk, setListMerk] = useState([]);
  const [noInternet, setNoInternet] = useState(false);
  // const [isVisible, setIsVisible] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    setLoadMerk(true);
    setTimeout(() => {
      setLoadMerk(false);
    }, 600);
    setTimeout(() => {
      getMerk();
    }, 600);
  }, []);

  const getMerk = async () => {
    await axios
      .get(baseUrl + "/produk/v4/mst_motor_merk_taksasi", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        var merk = response.data.data;
        setListMerk(merk);
        // setTimeout(() => {
        //   setLoadMerk(false);
        // }, 1000);
      })
      .catch((error) => {
        // setIsTokenSame(true);
        // console.log(error);
        if (error.message === "Network Error") {
          setNoInternet(true);
        } else if(error.message === "Sesi anda telah habis , silahkan login ulang") {
          setIsTokenSame(true);
        }else{

          setIsError(true);
        }
      });
  };

  const pickElement = async (id, index) => {
    var tes = document.getElementsByClassName("swiper-slide-active");
    // console.log("elemen", tes[0].id);
    // console.log("id", id);
    if (String(id) === tes[0].id) {
      // console.log("ini merknmya sama");
      ambilMerk(id);
      setTempoMerk(index);
    }
  };

  return (
    <div>
      <center>
        <div
          onClick={() => {
            setSlidedInTipe(false);
            setSlidedInVarian(false);
            setSlidedInTahun(false);
            setSlidedInWilayah(false);
            setTimeout(() => {
              ambilMerk("");
              ambilTahun("");
              ambilVarian("");
              setTempoTahun("");
            }, 700);
          }}
          style={{
            position: "absolute",
            width: "100%",
            zIndex: "3",
            height: "26vh",
          }}
        ></div>
        <div
          id="picker"
          className={pickMerk !== "" ? "afterPick" : "beforePick"}
        >
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              initialSlide={pickMerk === "" ? 0 : tempoMerk}
              centeredSlides={true}
              slidesPerView={"auto"}
              //   style={{ width: "95vw", height: "150px" }}
              coverflowEffect={{
                rotate: 0,
                depth: 1000,
                slideShadows: false,
              }}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper"
            >
              {!imageLoaded && (
                <Skeleton
                  width={200}
                  height={200}
                  animation="wave"
                  variant="circular"
                />
              )}
              {listMerk.map((step, index) => (
                <SwiperSlide
                  onClick={() => {
                    pickElement(step.id, index);
                  }}
                  key={step.id}
                  id={step.id}
                  className={
                    pickTahun !== ""
                      ? "imageAfterMap"
                      : pickMerk !== ""
                      ? "imageAfter"
                      : "imageBefore"
                  }
                >
                  <LazyLoadImage
                    alt={`card${index}`}
                    src={baseImage + step.image}
                    effect="blur"
                    wrapperClassName="lazy-load-image-wrapper" // Add your custom class if needed
                    className="lazy-load-image" // Add your custom class if needed
                    beforeLoad={handleImageLoad} // Handle the image load event
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </center>

      <NetworkError
        setNoInternet={setNoInternet}
        noInternet={noInternet}
        action={getMerk}
      />
      <DialogError isOpen={listMerk.length === 0 && isError ? true : false} />
    </div>
  );
};

export default Merk;
