import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Search } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const BottomSheet = ({ item, index, searchTipe, onClick, selectedArea }) => {
  return (
    <div>
      {item.toString().toLowerCase().includes(searchTipe.toLowerCase()) && (
        <div
          onClick={onClick}
          style={{
            background:
              selectedArea && selectedArea.wilayah === item ? "#21565A" : "",
          }}
          className="px-3 py-3 "
        >
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <div style={{ fontSize: "14px" }}>{item}</div>
            <div>
              <ChevronRightIcon style={{ fontSize: "20px" }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BottomSheet;
