import React, { useState, useMemo, useRef, useEffect } from "react";
import TinderCard from "react-tinder-card";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Taksasi.css";
import { Button, Grid, Checkbox } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import axios from "axios";
import Loading from "./Loading";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Grow from "@mui/material/Grow";
import YoutubeEmbed from "../components/YouTube";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDownward";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CircularProgress from "@mui/material/CircularProgress";
import Sound from "../images/sound.mp3";
import Ringtone from "../images/ringtone.mp3";
import Skeleton from "@mui/material/Skeleton";
import LazyImage from "react-lazy-blur-image";
import NetworkError from "../components/NetworkError";
import ClearIcon from "@mui/icons-material/Clear";
import ReplyIcon from "@mui/icons-material/Reply";
import CheckIcon from "@mui/icons-material/Check";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { styled } from "@mui/system";
import DialogError from "./DialogError";

const baseUrl = process.env.REACT_APP_BASE_URL;
const baseImage = process.env.REACT_APP_BASE_URL_IMAGE;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 40px 40px 0px 0px;
  }
`;

const theme = createTheme({
  palette: {
    primer: { main: "#20929D" },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Taksasi = ({
  pickTipe,
  pickMerk,
  pickTahun,
  pickLokasi,
  pickVarian,
  isMilik,
  openYoutube,
  idYoutube,
  setOpenYoutube,
  setIdYoutube,
  hasilTaksasi,
  setHasilTaksasi,
  setZoomedInTaksasi,
  setSlidedInWilayah,
  setIsMilik,
  setPickLokasi,
  idHasilTaksasi,
  setIdHasilTaksasi,
  setToken,
  token,
}) => {
  const [pertanyaan, setPertanyaan] = useState([]);
  const [totalPertanyaan, setTotalPertanyaan] = useState();
  const [responTaksasi, setResponTaksasi] = useState([]);
  const [lastAnswer, setLastAnswer] = useState([]);
  let [looping, setLooping] = useState(0);
  const [lastDirection, setLastDirection] = useState();
  const [hargaAwal, setHargaAwal] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(pertanyaan.length);
  const currentIndexRef = useRef(currentIndex);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [answerRadio, setAnswerRadio] = useState(null);
  const [playAudio, setPlayAudio] = useState(false);
  const [loadSoal, setLoadSoal] = useState(false);
  const [slideCheckbox, setSlideCheckbox] = useState(true);
  const [slideTinder, setSlideTinder] = useState(true);
  const [arahSwipe, setArahSwipe] = useState("");
  const [stepAnswer, setStepAnswer] = useState([]);
  const [listIdExcept, setListIdExcept] = useState([]);
  const [noInternet, setNoInternet] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [assignChar, setAssignChar] = useState(null);
const [isError, setIsError] = useState(false);


  // const audioMptri = new Audio(Sound);
  // audioMptri.loop = true;
  // const audio = new Audio(Ringtone);
  const audio = useMemo(() => new Audio(Ringtone), [Ringtone]);
  audio.loop = true;

  // const canSwipe = currentIndex == 0;

  const handleClickOpen = (video) => {
    setOpenYoutube(true);
    setIdYoutube(video);
  };

  const handleAnswerRadio = (event) => {
    setAnswerRadio(event.target.value);
    // setIdYoutube("rokGy0huYEA");
    // console.log("ini radio", event.target.value);
  };

  useEffect(() => {
    // console.log('ss', baseUrl)
    getPertanyaan();
  }, [responTaksasi]);

  const handleOpenDialog = (param) => {
    setAssignChar(param);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    // Reset the checked value to false for all spareparts
    const updatedPertanyaan = [...pertanyaan];
    updatedPertanyaan[0].sparepart.forEach((sparepart) => {
      sparepart.value = false;
    });

    setListIdExcept([]);
    setAnswerRadio(null);
    setPertanyaan(updatedPertanyaan);

    setOpenDialog(false);
  };
  const getPertanyaan = async () => {
    var dataPertanyaan = {
      user: {
        id_app_user: 3483,
      },
      data_other: {
        start_date: "2022-11-24 14:02:59",
        end_date: "2022-11-24 14:02:59",
        id_except_sparepart:
          stepAnswer.length === 0
            ? []
            : stepAnswer[stepAnswer.length - 1].id_except_sparepart,
      },
      spesifikasi_motor: {
        id_mst_motor_merk: Number(pickMerk),
        tipe_unit: pickTipe,
        varian: pickVarian,
        id_mst_wilayah: Number(pickLokasi),
        tahun: Number(pickTahun),
      },
      ownership: isMilik,
      taksasi:
        stepAnswer.length === 0
          ? []
          : stepAnswer[stepAnswer.length - 1].step_respon_taksasi,
    };
    // console.log("data pertanyaan", String(dataPertanyaan));
    // console.log("ini respon", responTaksasi);
    let formData = new FormData();
    formData.set("data", JSON.stringify(dataPertanyaan));
    await axios
      .post(baseUrl + "/produk/v3/taksasi/pertanyaan", formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // console.log("respon", response.data.data);
        if (response.data.data.result === null) {
          let data = [
            // {
            //   id_taksasi_master: 0,
            //   pertanyaan: "Blurr",
            //   photo:
            //     "https://img.freepik.com/free-vector/blurred-background-with-light-colors_1034-245.jpg",
            //   sparepart: "Blurr",
            // },
          ];
          data.push(response.data.data.question[0]);
          for (let i = 0; i < data[0].sparepart.length; i++) {
            data[0].sparepart[i].value = listIdExcept.includes(
              data[0].sparepart[i].id_sparepart_master
            )
              ? true
              : false;
          }
          data[0].harga_pasar = response.data.data.harga_pasar;
          data[0].id_except = response.data.data.other.id_except_sparepart;
          setPertanyaan(data);
          setTotalPertanyaan(response.data.data.total_pertanyaan);
          // console.log("data Pertanyaan", data);
          setLoadSoal(false);
          setHargaAwal(response.data.data.harga_pasar_fixed);
        } else {
          let data = [
            {
              id_taksasi_master: 0,
              id_tipe_field: 1,
              harga_pasar: lastAnswer.harga_pasar_temp,
              pertanyaan: lastAnswer.pertanyaan_last,
              video: "",
              photo:
                "https://img.freepik.com/free-vector/blurred-background-design_1107-224.jpg?w=2000",
              sparepart: "Blurr",
            },
          ];
          setPertanyaan(data);
          setLoadSoal(false);
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
            setZoomedInTaksasi(false);
            setTimeout(() => {
              setIdHasilTaksasi(response.data.data.result.id_result_taksasi);
              setHasilTaksasi(true);
            }, 200);
          }, 4000);
        }
        setSlideCheckbox(true);
      })
      .catch((error) => {
        // console.log(error);
        if (error.message === "Network Error") {
          setNoInternet(true);
        }
        setIsError(true)
      });
  };

  const childRefs = useMemo(
    () =>
      Array(pertanyaan.length)
        .fill(0)
        .map((i) => React.createRef()),
    [pertanyaan.length]
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const swiped = (
    direction,
    id_taksasi,
    id_tipe_field,
    harga_temp,
    pertanyaan,
    id_except,
    index
  ) => {
    setLoadSoal(true);
    setLastDirection(direction);
    updateCurrentIndex(index);
    let jawabanTerakhir = {
      id_taksasi_master: id_taksasi,
      id_tipe_field: id_tipe_field,
      harga_pasar_temp: harga_temp,
      pertanyaan_last: pertanyaan,
      id_except: id_except,
      answer: direction === "right" ? true : false,
    };
    setLastAnswer(jawabanTerakhir);

    var isExist = false;
    for (let i = 0; i < responTaksasi.length; i++) {
      if (responTaksasi[i].id_taksasi_master === id_taksasi) {
        isExist = true;
      }
    }
    // console.log("is exist", isExist);
    if (isExist === false) {
      setStepAnswer((stepAnswer) => [
        ...stepAnswer,
        {
          id_except_sparepart: id_except,
          step_respon_taksasi: [
            ...responTaksasi,
            {
              id_taksasi_master: id_taksasi,
              id_tipe_field: id_tipe_field,
              harga_pasar_temp: harga_temp,
              answer: direction === "right" ? true : false,
            },
          ],
        },
      ]);
      setResponTaksasi((responTaksasi) => [
        ...responTaksasi,
        {
          id_taksasi_master: id_taksasi,
          id_tipe_field: id_tipe_field,
          harga_pasar_temp: harga_temp,
          answer: direction === "right" ? true : false,
        },
      ]);
    }

    // console.log("respon co", responTaksasi);
    // getPertanyaan()
    setPlayAudio(false);
    setSlideCheckbox(true);
    audio.pause();
    audio.loop = false;
    audio.currentTime = 0;
  };

  const swipe = async (dir) => {
    await childRefs[pertanyaan.length - 1].current.swipe(dir); // Swipe the card!
  };

  const goBack = async () => {
    setLoadSoal(true);
    setStepAnswer(stepAnswer.slice(0, -1));
    setResponTaksasi(responTaksasi.slice(0, -1));
    setPlayAudio(false);
    setSlideCheckbox(true);
    audio.pause();
    audio.loop = false;
    audio.currentTime = 0;
  };

  const outOfFrame = (pertanyaan, idx) => {
    // console.log(
    //   `${pertanyaan} (${idx}) left the screen!`,
    //   currentIndexRef.current
    // );
    // currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
  };

  const checkUp = (value, index, id_sparepart_master) => {
    // console.log("pertanyaan", event.target.checked);
    // console.log('value', value)
    // console.log('index', index)
    var data = [...pertanyaan];
    if (value === true) {
      data[0].sparepart[index].value = false;
    } else {
      data[0].sparepart[index].value = true;
    }
    if (listIdExcept.includes(id_sparepart_master)) {
      // var indexId = listIdExcept.indexOf(id_sparepart_master);
      // setListIdExcept(listIdExcept.splice(indexId, 0))
      setListIdExcept((listIdExcept) =>
        listIdExcept.filter((id) => id !== id_sparepart_master)
      );
    } else {
      setListIdExcept((listIdExcept) => [...listIdExcept, id_sparepart_master]);
    }
    setPertanyaan(data);
  };

  const jawabMultiple = (
    id,
    tipe_field,
    harga_pasar,
    pertanyaan_last,
    id_except
  ) => {
    setLoadSoal(true);

    // console.log('jawab multiple', id +"kad"+ tipe_field)
    let jawabanTerakhir = {
      id_taksasi_master: id,
      id_tipe_field: tipe_field,
      harga_pasar_temp: harga_pasar,
      pertanyaan_last: pertanyaan_last,
      id_except: id_except,
      answer: "multiple",
    };
    // console.log("jawaban trakhir", jawabanTerakhir);
    setLastAnswer(jawabanTerakhir);

    if (tipe_field === 2) {
      var data = [];
      for (let i = 0; i < pertanyaan[0].sparepart.length; i++) {
        if (pertanyaan[0].sparepart[i].value === true) {
          data.push({
            id_sparepart_master: pertanyaan[0].sparepart[i].id_sparepart_master,
          });
        }
      }

      var isExist = false;
      for (let i = 0; i < responTaksasi.length; i++) {
        if (responTaksasi[i].id_taksasi_master === id) {
          isExist = true;
        }
      }
      // console.log("is exist", isExist);
      if (isExist === false) {
        setStepAnswer((stepAnswer) => [
          ...stepAnswer,
          {
            id_except_sparepart: id_except,
            step_respon_taksasi: [
              ...responTaksasi,
              {
                id_taksasi_master: id,
                id_tipe_field: tipe_field,
                harga_pasar_temp: harga_pasar,
                multiple_answer: data,
              },
            ],
          },
        ]);
        setResponTaksasi((responTaksasi) => [
          ...responTaksasi,
          {
            id_taksasi_master: id,
            id_tipe_field: tipe_field,
            harga_pasar_temp: harga_pasar,
            multiple_answer: data,
          },
        ]);
      }
      // console.log("jawab choice", data);
    } else if (tipe_field === 3) {
      setResponTaksasi((responTaksasi) => [
        ...responTaksasi,
        {
          id_taksasi_master: id,
          id_tipe_field: tipe_field,
          harga_pasar_temp: harga_pasar,
          multiple_answer: [
            {
              id_sparepart_master: Number(answerRadio),
            },
          ],
        },
      ]);
    }
    setPlayAudio(false);
    audio.pause();
    audio.loop = false;
    audio.currentTime = 0;
  };

  const accVerifikasi = () => {
    // console.log("jate", lastAnswer);
    setResponTaksasi((responTaksasi) => [...responTaksasi, lastAnswer]);
  };

  const likeOrNot = (dir) => {
    let right = document.getElementById("swipeRight");
    let left = document.getElementById("swipeLeft");
    if (dir == "right") {
      right.classList.add("showSwipe");
      left.classList.remove("showSwipe");
    } else if (dir == "left") {
      right.classList.remove("showSwipe");
      left.classList.add("showSwipe");
    } else {
      right.classList.remove("showSwipe");
      left.classList.remove("showSwipe");
    }
    console.log("arah", arahSwipe);
  };

  const netral = () => {
    let right = document.getElementById("swipeRight");
    let left = document.getElementById("swipeLeft");
    right.classList.remove("showSwipe");
    left.classList.remove("showSwipe");
  };

  const backToLocation = () => {
    setZoomedInTaksasi(false);
    setHasilTaksasi(false);
    setTimeout(() => {
      setSlidedInWilayah(true);
      setIsMilik(null);
      setPickLokasi("");
    }, 500);
  };

  return (
    <div>
      <StyledDialog
        fullScreen
        sx={{
          marginTop: "65px",
        }}
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <Toolbar
          sx={{
            position: "relative",
            bgcolor: "#10494E",
            marginBottom: "-10px",
            justifyContent: "right",
          }}
        >
          <Button
            sx={{ color: "white", fontFamily: "Poppins" }}
            onClick={handleCloseDialog}
          >
            Tutup
          </Button>
        </Toolbar>
        <List
          sx={{
            bgcolor: "#10494E",
            paddingTop: "30px",
            height: "100vh",
            position: "relative",
          }}
        >
          <div className="frame px-3">
            <div
              className="mb-3"
              style={{
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "700",
              }}
            >
              {pertanyaan[0]?.pertanyaan}
            </div>
            {assignChar?.sparepart.map((choice, index) => (
              <div key={index}>
                {choice.harga !== 0 ? (
                  <div key={index} className="d-flex justify-content-start">
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          className="py-1"
                          onChange={() => {
                            checkUp(
                              choice.value,
                              index,
                              choice.id_sparepart_master
                            );
                          }}
                          style={{
                            color: "white",
                            fontSize: "5px",
                          }}
                          checked={choice.value}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: "12px",
                            color: "white",
                            fontFamily: "Poppins",
                          }}
                        >
                          {choice.nama_sparepart}
                        </span>
                      }
                    />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              padding: "16px",
            }}
          >
            <Button
              disabled={
                !pertanyaan?.some(
                  (choice) =>
                    Array.isArray(choice?.sparepart) &&
                    choice?.sparepart?.some((sparepart) => sparepart.value)
                )
              }
              style={{
                height: "60px",
                borderRadius: "50px",
                backgroundColor: "#FFFFFF",
              }}
              variant="contained"
              onClick={() => {
                jawabMultiple(
                  assignChar.id_taksasi_master,
                  2,
                  assignChar.harga_pasar,
                  assignChar.pertanyaan,
                  assignChar.id_except
                );
                setSlideCheckbox(false);
                handleCloseDialog();
                // setOpenDialog(false)
              }}
            >
              <CheckIcon
                style={{
                  color: !pertanyaan?.some(
                    (choice) =>
                      Array.isArray(choice?.sparepart) &&
                      choice?.sparepart?.some((sparepart) => sparepart.value)
                  )
                    ? "#BABEC1"
                    : "#20929D",
                }}
              />
            </Button>
          </div>
        </List>
      </StyledDialog>
      {isLoading ? (
        <Loading
          setOpen={setOpen}
          open={open}
          setHasilTaksasi={setHasilTaksasi}
          setZoomedInTaksasi={setZoomedInTaksasi}
        />
      ) : null}

      {pertanyaan.length !== 0 ? (
        <div
          style={{
            backgroundColor: "#B5DBDE",
            height: "100vh",
            overflow: "hidden",
            borderTopRightRadius: "40px",
            borderTopLeftRadius: "40px",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
            className="p-3"
          >
            <div
              className="d-flex align-items-center px-3"
              style={{ justifyContent: "space-between" }}
            >
              <ArrowBackIosIcon
                style={{
                  color: "#10494E",
                  fontSize: "24px",
                  fontWeight: 700,
                  float: "left",
                }}
                fontSize="small"
                onClick={() => backToLocation()}
              />
              <div
                style={{ fontSize: "24px", fontWeight: 700, color: "#10494E" }}
              >
                {pickVarian.length > 9
                  ? pickVarian.substring(0, 9) + "..."
                  : pickVarian + " " + pickTahun}
              </div>
              <ArrowBackIosIcon
                style={{ fontSize: "24px", fontWeight: 700, opacity: 0 }}
                fontSize="small"
              />
            </div>
          </div>
          <center
            style={{
              backgroundColor: "#10494E",
              height: "100vh",
              overflow: "hidden",
              borderTopRightRadius: "40px",
              borderTopLeftRadius: "40px",
              color: "white",
            }}
          >
            <div container className="p-3 d-flex justify-content-center">
              <div style={{ fontSize: "14px" }}>
                Estimasi Harga Pasaran{" "}
                <span style={{ fontWeight: 800 }}>
                  Rp. {hargaAwal.toLocaleString("de-DE")}{" "}
                </span>
              </div>
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <div
                style={{
                  backgroundImage:
                    "url(" +
                    "https://img.freepik.com/free-vector/blurred-background-design_1107-224.jpg?w=2000" +
                    ")",

                  position: "absolute",
                  backgroundSize: "100% 100%",
                  height: "40vh",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "left top",
                }}
                className="card justify-content-center"
              >
                {loadSoal === true ? (
                  <div>
                    <Grid>
                      <CircularProgress sx={{ color: "white" }} />
                    </Grid>
                    <Grid>
                      <div style={{ color: "white" }}>Mohon Tunggu ...</div>
                    </Grid>
                  </div>
                ) : null}
              </div>
            </div>

            <Grid container rowSpacing={6} className="pt-0">
              <Grid item xs={12}>
                <div style={{ height: "40vh" }}>
                  {pertanyaan.map((character, index) => (
                    <div
                      key={character.id_taksasi_master}
                      className="d-flex justify-content-center"
                    >
                      {character.id_tipe_field === 1 ? (
                        <TinderCard
                          ref={childRefs[index]}
                          className="swipe"
                          onSwipe={(dir) =>
                            swiped(
                              dir,
                              character.id_taksasi_master,
                              character.id_tipe_field,
                              character.harga_pasar,
                              character.pertanyaan,
                              character.id_except,
                              index
                            )
                          }
                          onCardLeftScreen={() =>
                            outOfFrame(character.pertanyaan, index)
                          }
                          swipeThreshold={50}
                          swipeRequirementType={"position"}
                          preventSwipe={["up", "down"]}
                          onSwipeRequirementFulfilled={(dir) => likeOrNot(dir)}
                          onSwipeRequirementUnfulfilled={() => netral()}
                        >
                          <Grow
                            in={slideTinder}
                            {...(slideTinder ? { timeout: 700 } : {})}
                          >
                            <div
                              className="card"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40vh",
                              }}
                            >
                              {character.id_taksasi_master === 0 ? (
                                <img
                                  className="card"
                                  style={{
                                    width: "100%",
                                    height: "40vh",
                                  }}
                                  alt=""
                                  src={character.photo}
                                />
                              ) : (
                                <div>
                                  <LazyImage
                                    uri={baseImage + character.photo}
                                    placeholder={
                                      baseImage + character.photo_blur
                                    }
                                    render={(src) => (
                                      <img
                                        className="card"
                                        style={{
                                          width: "100vw",
                                          height: "40vh",
                                        }}
                                        alt=""
                                        src={src}
                                      />
                                    )}
                                  />
                                </div>
                              )}
                              <div
                                id="swipeRight"
                                className="swipeRight hidden"
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    height: "60px",
                                    borderRadius: "50px",
                                    backgroundColor: "white",
                                    color: "#20929D",
                                  }}
                                >
                                  <CheckIcon />
                                </Button>
                              </div>
                              <div id="swipeLeft" className="swipeLeft hidden">
                                <Button
                                  variant="contained"
                                  style={{
                                    height: "60px",
                                    borderRadius: "50px",
                                    backgroundColor: "white",
                                    color: "red",
                                  }}
                                >
                                  <CloseIcon />
                                </Button>
                              </div>
                              {character.video !== "" ? (
                                <PlayCircleIcon
                                  style={{
                                    fontSize: "50px",
                                    color: "#20929D",
                                    backgroundColor: "white",
                                    borderRadius: "50px",
                                    zIndex: "10",
                                    position: "absolute",
                                  }}
                                  onClick={() =>
                                    handleClickOpen(character.video)
                                  }
                                />
                              ) : null}
                            </div>
                          </Grow>
                        </TinderCard>
                      ) : (
                        <Slide in={slideCheckbox} direction="left">
                          <div
                            className="card"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              height: "40vh",
                            }}
                          >
                            <LazyImage
                              uri={baseImage + character.photo}
                              placeholder={baseImage + character.photo_blur}
                              render={(src) => (
                                <img
                                  className="card"
                                  style={{
                                    width: "100vw",
                                    height: "40vh",
                                  }}
                                  alt=""
                                  src={src}
                                />
                              )}
                            />
                            {character.video !== "" ? (
                              <PlayCircleIcon
                                style={{
                                  fontSize: "50px",
                                  color: "#20929D",
                                  backgroundColor: "white",
                                  borderRadius: "50px",
                                  zIndex: "10",
                                  position: "absolute",
                                }}
                                onClick={() => handleClickOpen(character.video)}
                              />
                            ) : null}
                          </div>
                        </Slide>
                      )}
                    </div>
                  ))}
                </div>
                {/* <div
                style={{
                  color: "#717375",
                  fontSize: "11px",
                  width: "250px",
                  textAlign: "left",
                }}
              >
                *Foto/Gambar hanya ilustrasi
              </div> */}
              </Grid>

              <Grid item xs={12} className="pt-2">
                <div>
                  <div
                    className="pt-0 pb-0 infoText"
                    style={{
                      width: "80%",
                    }}
                  >
                    {loadSoal === true ? (
                      <div>
                        <div style={{ height: "20vh", maxWidth: "300px" }}>
                          <Skeleton
                            variant="text"
                            width={200}
                            sx={{ fontSize: "1rem" }}
                          />
                          <Skeleton
                            variant="text"
                            width={250}
                            sx={{ fontSize: "1rem" }}
                          />
                          <Skeleton
                            variant="text"
                            width={190}
                            sx={{ fontSize: "1rem" }}
                          />
                          <Skeleton
                            variant="text"
                            width={300}
                            sx={{ fontSize: "1rem" }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "white",
                          fontWeight: 700,
                          maxWidth: "300px",
                        }}
                      >
                        <div className="d-flex" style={{ height: "20vh" }}>
                          <div>{pertanyaan[0]?.pertanyaan}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="buttons pt-0 justify-content-center">
                    {pertanyaan.map((character, index) => (
                      <div key={index}>
                        {
                          character.id_tipe_field === 1 ? (
                            <Grid container className="pt-0" spacing={2}>
                              <Grid item xs={4}>
                                <Button
                                  disabled={loadSoal}
                                  variant="contained"
                                  style={{
                                    height: "60px",
                                    borderRadius: "50px",
                                    backgroundColor: "white",
                                    color: "red",
                                  }}
                                  onClick={() => swipe("left")}
                                >
                                  <ClearIcon />
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  variant="contained"
                                  style={{
                                    height: "60px",
                                    borderRadius: "50px",
                                    backgroundColor: "white",
                                    color: "#E0AA06",
                                  }}
                                  onClick={() => goBack()}
                                >
                                  <ReplyIcon />
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  disabled={loadSoal}
                                  variant="contained"
                                  style={{
                                    height: "60px",
                                    borderRadius: "50px",
                                    backgroundColor: "white",
                                    color: "#20929D",
                                  }}
                                  onClick={() => {
                                    swipe("right");
                                  }}
                                >
                                  <CheckIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          ) : (
                            <div>
                              <Grid container className="pt-0">
                                <Button
                                  variant="contained"
                                  style={{
                                    height: "60px",
                                    borderRadius: "50px",
                                    backgroundColor: "white",
                                    color: "#E0AA06",
                                  }}
                                  onClick={() => goBack()}
                                >
                                  <ReplyIcon />
                                </Button>
                                <Button
                                  style={{
                                    height: "60px",
                                    borderRadius: "50px",
                                    backgroundColor: "white",
                                    color: "#20929D",
                                  }}
                                  onClick={() => handleOpenDialog(character)}
                                  variant="contained"
                                >
                                  <FormatListBulletedIcon />
                                </Button>
                              </Grid>
                            </div>
                          )
                          // : (
                          //   <div style={{ width: "90vw" }}>
                          //     <ThemeProvider theme={theme}>
                          //       <FormControl>
                          //         <RadioGroup
                          //           aria-labelledby="demo-radio-buttons-group-label"
                          //           name="radio-buttons-group"
                          //         >
                          //           {character.sparepart.map((choice, index) => (
                          //             <div
                          //               key={index}
                          //               className="d-flex justify-content-start"
                          //               style={{ width: "80vw" }}
                          //             >
                          //               <FormControlLabel
                          //                 value={choice.id_sparepart_master}
                          //                 // checked={false}
                          //                 size="small"
                          //                 onChange={handleAnswerRadio}
                          //                 control={<Radio color="primer" />}
                          //                 label={choice.nama_sparepart}
                          //               />
                          //             </div>
                          //           ))}
                          //         </RadioGroup>
                          //       </FormControl>
                          //     </ThemeProvider>
                          //     <Grid container className="pt-0" spacing={2}>
                          //       <Button
                          //         className="buttonCheckbox"
                          //         onClick={() => goBack()}
                          //         variant="text"
                          //       >
                          //         <ReplyIcon />
                          //       </Button>

                          //       <Button
                          //         onClick={() =>
                          //           jawabMultiple(
                          //             character.id_taksasi_master,
                          //             3,
                          //             character.harga_pasar,
                          //             character.pertanyaan,
                          //             character.id_except
                          //           )
                          //         }
                          //         style={{
                          //           height: "60px",
                          //           borderRadius: "50px",
                          //           backgroundColor: "white",
                          //           color: "#20929D",
                          //         }}
                          //         variant="contained"
                          //       >
                          //         <FormatListBulletedIcon />
                          //       </Button>
                          //     </Grid>
                          //   </div>
                          // )
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          </center>
        </div>
      ) : null}
      <NetworkError
        setNoInternet={setNoInternet}
        noInternet={noInternet}
        action={getPertanyaan}
      />
      <DialogError isOpen={pertanyaan.length === 0 && isError ? true : false} />

    </div>
  );
};

export default Taksasi;
