import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Navbar = ({  onClick, text, color }) => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor:"#1B7A83 "
      }}
      className="p-3"
    >
      <div style={{textAlign:"left"}} className="d-flex align-items-center">
        <ArrowBackIosIcon style={{color:color, fontSize:'24px', fontWeight:700}} fontSize="small"  onClick={onClick} />
        <div style={{fontSize:'24px', fontWeight:700, color:color}}>{text}</div>
      </div>
    </div>
  );
};

export default Navbar;
