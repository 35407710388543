import React, { useState, forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import drivingVespa from "../images/drivingVespa.gif";
import loadBg from "../images/bgLoadRes.webp";

import Slide from "@mui/material/Slide";
import "./loading.css";
import { styled } from "@mui/system";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
  }
`;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Loading = ({ setOpen, open, setHasilTaksasi, setZoomedInTaksasi }) => {
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    else setOpen(false);
  };

  return (
    <div>
      <StyledDialog
        disableEscapeKeyDown
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          className="d-flex"
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={loadBg}
            alt="load"
            style={{ width: "350px", position: "fixed", zIndex: 0 }}
          />
          <img
            src={drivingVespa}
            alt="vespa"
            style={{
              width: "100%",
              zIndex: 1,
            }}
          />
          <p
            style={{
              color: "white",
              fontSize: "18px",
              fontWeight: 700,
              zIndex: 1,
              fontFamily:'Poppins',
              marginBottom: '30px'
            }}
          >
            Tunggu sebentar ya sob <span className="wave">.</span>
            <span className="wave1">.</span>
            <span className="wave2">.</span>
          </p>
        </div>
      </StyledDialog>
    </div>
  );
};

export default Loading;
