import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import { Slide, Item } from "@mui/material";
import "./Tipe.css";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import NetworkError from "../../components/NetworkError";
import BottomSheet from "../../components/BottomSheet";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IndonesianMap from "../../components/IndonesianMap";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CekMilik from "../../components/CekMilik";
import DialogError from "../../components/DialogError";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Wilayah = ({
  pickMerk,
  ambilWilayah,
  pickTipe,
  pickTahun,
  pickVarian,
  setSlidedInWilayah,
  setSlidedInMilik,
  slidedInMilik,
  setIsMilik,
  isMilik,
  setZoomedInTaksasi,
  slidedInWilayah,
  setSlided,
  setSearchTipe,
  searchTipe,
  token,
}) => {
  const [listWilayah, setListWilayah] = useState([]);
  const [loadWilayah, setLoadWilayah] = useState(true);
  const [noInternet, setNoInternet] = useState(false);
  const [selectedArea, setSelectedArea] = useState("");
  const [isError, setIsError] = useState(false)
  
  const containerRef = useRef(null);

  const [tempWilayah, setTempWilayah] = useState(null);

  useEffect(() => {
    if (containerRef.current && selectedArea.id) {
      // Find the selected item element by its ID
      const selectedItemElement = containerRef.current.querySelector(
        `#_${selectedArea.id}`
      );

      if (selectedItemElement) {
        // Scroll to the selected item's position within the container
        containerRef.current.scrollTo({
          top: selectedItemElement.offsetTop,
          behavior: "smooth",
        });
      }
    }
    const delay = setTimeout(() => {
      setSlidedInWilayah(true);
    }, 1000);

    getWilayah();
    return () => clearTimeout(delay);
  }, [slidedInWilayah, selectedArea.id]);

  const getWilayah = async () => {
    var params = new URLSearchParams();

    params.append("id_mst_motor_merk", pickMerk);
    params.append("tipe_unit", pickTipe);
    params.append("varian", pickVarian);
    params.append("tahun", pickTahun);
    var request = {
      params: params,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios
      .get(baseUrl + "/produk/v4/produk_taksasi", request)
      .then((response) => {
        setListWilayah(response.data.data);
        setLoadWilayah(false);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setNoInternet(true);
        }
        console.log(error.message);
        setIsError(true)
      });
  };

  const tempStep = (item) => {
    const combinedParam = {
      id: parseInt(item.id_mst_wilayah),
      wilayah: item.wilayah,
    };
    setSelectedArea(combinedParam);
    setTempWilayah(item.id_mst_wilayah);
  };
  const handleClick = (param) => {
    if (typeof param === "object") {
      setSelectedArea(param);
      setTempWilayah(param.id);
    } else {
      const [id, name] = param.split("_");
      const combinedParam = {
        id: parseInt(id),
        wilayah: name,
      };
      setSelectedArea(combinedParam);
      setTempWilayah(combinedParam.id);
    }
  };
  const nextStep = (param) => {
    setSlidedInMilik(false);
    setZoomedInTaksasi(true);
    setSlided(true);

    setTimeout(() => {
      setIsMilik(param);
      ambilWilayah(tempWilayah);
    }, 700);
  };
  const openIsMilik = () => {
    setSlidedInWilayah(false);
    setTimeout(() => {
      setIsMilik(true);
      setSlidedInMilik(true);
    }, 700);
  };
  return (
    <div>
      <div
        style={{
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
        }}
      >
        <div
          style={{
            overflowY: "scroll",
          }}
        >
          {!isMilik ? (
            <Slide direction="up" in={slidedInWilayah} timeout={700}>
              {loadWilayah === true ? (
                <div
                  style={{
                    backgroundColor: "#10494E",
                    maxHeight: "340px",
                    overflow: "hidden",
                    borderTopRightRadius: "40px",
                    borderTopLeftRadius: "40px",
                  }}
                >
                  {[...Array(12)].map((e, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          height: "30px",
                          borderRadius: "10px",
                          alignItems: "center",
                        }}
                        className=" mt-2 pt-2 px-3 mx-1"
                      >
                        <Skeleton
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "20px",
                            bgcolor: "#1B7A83",
                          }}
                          animation="wave"
                          variant="rectangular"
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  <div
                    className="d-flex justify-content-center"
                    style={{
                      backgroundColor: "#B5DBDE",
                      height: "120px",
                      zIndex: 1,
                      marginBottom: "-18%",
                      borderTopRightRadius: "40px",
                      borderTopLeftRadius: "40px",
                      textAlign: "center",
                    }}
                  >
                    <p
                      className="pt-2 d-flex"
                      style={{
                        maxWidth: "90%",
                        color: "#10494E",
                        fontWeight: "700",
                        maxHeight: "45px",
                        alignItems: "center",
                      }}
                    >
                      {listWilayah[0].merk + " " + pickVarian + " " + pickTahun}
                    </p>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#10494E",
                      borderTopRightRadius: "40px",
                      borderTopLeftRadius: "40px",
                      zIndex: 2,
                    }}
                  >
                    <div
                      style={{ display: "grid", placeItems: "center" }}
                      className="py-3"
                    >
                      <IndonesianMap
                        listWilayah={listWilayah}
                        selectedArea={selectedArea}
                        onClick={handleClick}
                      />
                    </div>

                    <Stack
                      spacing="0"
                      ref={containerRef}
                      divider={<Divider orientation="vertical" flexItem />}
                      style={{
                        overflowY: "auto",
                        maxHeight: "21vh",
                        color: "white",
                        position: "relative",
                      }}
                    >
                      {listWilayah.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => tempStep(item)}
                          style={{
                            background:
                              selectedArea &&
                              selectedArea.id === item.id_mst_wilayah
                                ? "#21565A"
                                : "",
                            cursor: "pointer",
                          }}
                          id={`_${item.id_mst_wilayah}`}
                          className="px-3 py-3 "
                        >
                          <div
                            key={index}
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontSize: "14px" }}>
                              {item.wilayah}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Stack>
                    <div className="p-3">
                      <Button
                        disableElevation
                        className="w-100"
                        style={{ backgroundColor: "#1B7A83" }}
                        variant="contained"
                        onClick={() => openIsMilik()}
                      >
                        Pilih
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Slide>
          ) : (
            <Slide direction="up" in={slidedInMilik} timeout={700}>
              <div
                style={{
                  backgroundColor: "#10494E",
                  height: "90vh",
                  overflow: "hidden",
                  borderTopRightRadius: "40px",
                  borderTopLeftRadius: "40px",
                }}
              >
                <CekMilik onClick={nextStep} />
              </div>
            </Slide>
          )}
        </div>
      </div>
      <NetworkError
        setNoInternet={setNoInternet}
        noInternet={noInternet}
        action={getWilayah}
      />
      <DialogError isOpen={listWilayah.length === 0 && isError ? true : false} />

    </div>
  );
};

export default Wilayah;
