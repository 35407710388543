import React from "react";
import loadBg from "../images/bgLoadRes.webp";
import drivingVespa from "../images/drivingVespa.gif";

const LoadMerk = () => {
  return (
    <div>
      <div
        className="d-flex"
        style={{
          backgroundColor: "#1B7A83",
          minHeight: "100vh",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={loadBg}
          alt="load"
          style={{ width: "300px", position: "fixed", zIndex: 0 }}
        />
        <img
          src={drivingVespa}
          alt="vespa"
          style={{
            width: "80%",
            mb: 5,
            zIndex: 1,
          }}
        />
        <p
          style={{
            color: "white",
            fontSize: "18px",
            fontWeight: 700,
            zIndex: 1,
            marginTop: '10%'
          }}
        >
          Tunggu sebentar ya sob <span className="wave">.</span>
          <span className="wave1">.</span>
          <span className="wave2">.</span>
        </p>
      </div>
    </div>
  );
};

export default LoadMerk;
