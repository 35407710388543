import Button from "@mui/material/Button";
import React from "react";
import Milik from "../images/milik.webp";
import { Slide } from "@mui/material";

const CekMilik = ({onClick}) => {
  return (
    <div className="p-3">
      <div
        className="d-flex"
        style={{ justifyContent: "center", color: "white" }}
      >
        <div className="py-2">
          <img src={Milik} width={"70%"} />
          <div>
            Sobat melakukan Taksasi untuk motor siapa ?
          </div>
          <div
            className="d-flex pt-3"
            style={{ flexDirection: "column", gap: 10 }}
          >
            <Button
              style={{
                color: "white",
                fontFamily:'Poppins',
                borderColor: "#20929D",
                width: "100%",
                justifyContent: "left",
                borderRadius: "16px",
                backgroundColor: '#20929D33'
              }}
              className="py-3"
              variant="outlined"
              onClick={() => onClick(1)}
            >
              Milik Sendiri
            </Button>
            <Button
              style={{
                color: "white",
                fontFamily:'Poppins',
                borderColor: "#20929D",
                width: "100%",
                justifyContent: "left",
                borderRadius: "16px",
              }}
              className="py-3"
              variant="outlined"
              onClick={() => onClick(2)}
            >
              Milik Orang Lain
            </Button>
            <Button
              style={{
                color: "white",
                fontFamily:'Poppins',
                borderColor: "#20929D",
                width: "100%",
                justifyContent: "left",
                borderRadius: "16px",
              }}
              className="py-3"
              variant="outlined"
              onClick={() => onClick(3)}
            >
              Iseng - iseng aja
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CekMilik;
