import React from "react";
import Home from "../views/Home.js";
import NotFound from "../views/NotFound.js";
import { Route, Switch } from "react-router-dom";
// import {AwalTest} from "../interface/mobile/Awal1.js";
import Swiper from "../views/Swiper.js";

const Routes = () =>{
    return(
        <React.Fragment>
            <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/swipe' exact component={Swiper} />
                {/* <Route path='/awal' exact component={AwalTest} /> */}
                <Route component={NotFound} />
            </Switch>
        </React.Fragment>
    )
}

export default Routes