// errorHandling.js
import React, { useState } from "react";
import {
  Dialog,
  Card,
  Button,
} from "@mui/material";
import { Slide } from "@mui/material";
import Error from '../images/errorMerk.webp'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogError = ({ isOpen }) => {
  const handleClose = () => {
    // Optionally, you can trigger a page refresh here
    window.location.reload();
  };

  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
      >
        <Card
          sx={{ height: "100vh", bgcolor: "#10494E", textAlign: "center", flexDirection:'column', alignItems:'center', placeContent:'center' }}
          className="p-3 d-flex"
        >
          <img
            src={Error}
            alt="update"
            style={{
              width:'200px',
              zIndex: 1,
            }}
          />
          <div
            className="mt-3"
            style={{
              userSelect: "none",
              color: "white",
              fontSize: "16px",
              fontWeight: 700,
              fontFamily: "Poppins",
            }}
          >
            Error
          </div>
          <div
            style={{
              userSelect: "none",
              color: "white",
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Poppins",
            }}
            className="mt-2"
          >
            Terjadi Kesalahan, coba lagi yukk ...
          </div>
          <Button
            className="mt-3"
            sx={{
              fontFamily: "Poppins",
              bgcolor: "#20929D",
              color: "white",
              borderRadius: "10px",
              width: "100%",
            }}
            onClick={handleClose}
          >
            <div
              style={{
                userSelect: "none",
                fontWeight: 400,
                fontSize: "1em",
              }}
            >
              Kembali ke Halaman Awal
            </div>
          </Button>
        </Card>
      </Dialog>
    </>
  );
};

export default DialogError;
