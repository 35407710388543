import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import Merk from "./Merk";
import Awal from "./Awal";
import Tipe from "./Tipe";
import Lokasi from "./Lokasi";
import Tahun from "./Tahun";
import Taksasi from "../../components/Taksasi";
import HeaderImg from "../../images/header.png";
import LoadMerk from "../../components/LoadMerk";
import ResultTaksasi from "./ResultTaksasi";
import Navbar from "../../components/Navbar";
import "./Home.css";
import Zoom from "@mui/material/Zoom";
import Slide from "@mui/material/Slide";
import Varian from "./Varian";
import Tahun2 from "./Tahun2";
import Wilayah from "./Wilayah";
import { setupAxiosInterceptors } from "../../axiosConfig"; // Adjust the path accordingly

const Home = ({
  start,
  pickMerk,
  pickTipe,
  pickVarian,
  pickTahun,
  pickLokasi,
  jenisMotor,
  slided,
  slidedInVarian,
  slidedInTipe,
  slidedInTahun,
  slidedInWilayah,
  setStart,
  setPickMerk,
  setPickTipe,
  setPickVarian,
  setPickTahun,
  setPickLokasi,
  setSlided,
  setSlidedInVarian,
  setSlidedInTipe,
  setSlidedInTahun,
  setSlidedInWilayah,
  setSlidedInMilik,
  slidedInMilik,
  mulaiTaksasi,
  pilihMerk,
  pilihTipe,
  pilihVarian,
  pilihTahun,
  pilihLokasi,
  isMilik,
  setIsMilik,
  setJenisMotor,
  cekData,
  tempoTahun,
  setTempoTahun,
  tempoMerk,
  setTempoMerk,
  setSearchTipe,
  searchTipe,
  openYoutube,
  idYoutube,
  setOpenYoutube,
  setIdYoutube,
  hasilTaksasi,
  setHasilTaksasi,
  zoomed,
  setZoomed,
  setZoomedInLokasi,
  zoomedInLokasi,
  zoomedInAwal,
  setZoomedInAwal,
  zoomedInTaksasi,
  setZoomedInTaksasi,
  zoomedInResult,
  clearAll,
  idHasilTaksasi,
  setIdHasilTaksasi,
  setToken,
  token,
  version,
  setIsTokenSame,
}) => {
  const [loadMerk, setLoadMerk] = useState(true);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const cleanupAxiosInterceptors = setupAxiosInterceptors();

    // Clean up Axios interceptors when the app unmounts

    if (start) {
      setLoad(true);
      setTimeout(() => {
        setLoad(false);
      }, 1000);
    }
    return () => {
      cleanupAxiosInterceptors();
    };
  }, [start]);
  return (
    <div style={{ maxHeight: "100vh", backgroundColor: "#1B7A83 " }}>
      {load ? (
        <div>
          <LoadMerk />;
        </div>
      ) : (
        <div>
          {start && pickMerk === "" && !loadMerk ? (
            <Navbar
              color={"white"}
              onClick={() => {
                setZoomed(false);
                setTimeout(() => {
                  setZoomedInAwal(true);
                  setStart(false);
                  setPickTahun("");
                }, 500);
              }}
              text={"Merk Motor"}
            />
          ) : pickMerk !== "" && pickTipe === "" ? (
            <Navbar
              color={"white"}
              onClick={() => {
                setSlided(false);
                setSlidedInTipe(false);

                setTimeout(() => {
                  setPickMerk("");
                  setPickTahun("");
                }, 700);
              }}
              text={"Tipe Motor"}
            />
          ) : pickMerk !== "" && pickTipe !== "" && pickVarian === "" ? (
            <Navbar
              color={"white"}
              onClick={() => {
                setSlided(false);
                setSlidedInVarian(false);

                setTimeout(() => {
                  setPickTipe("");
                  setPickVarian("");
                }, 700);
              }}
              text={"Varian Motor"}
            />
          ) : pickMerk !== "" && pickTipe !== "" && pickTahun === "" ? (
            <Navbar
              color={"white"}
              onClick={() => {
                setSlided(false);
                setSlidedInTahun(false);
                setTimeout(() => {
                  setPickVarian("");
                  setPickTahun("");
                }, 700);
              }}
              text={"Tahun Motor"}
            />
          ) : pickMerk !== "" &&
            pickTipe !== "" &&
            pickTahun !== "" &&
            pickLokasi === "" &&
            isMilik === null ? (
            <Navbar
              color={"white"}
              onClick={() => {
                setSlided(false);
                setSlidedInWilayah(false);
                setTimeout(() => {
                  setTempoTahun(pickTahun);
                  setPickTahun("");
                  setPickLokasi("");
                }, 700);
                cekData();
              }}
              text={"Pilih Wilayah"}
            />
          ) : pickLokasi === "" && isMilik ? (
            <Navbar
              color={"white"}
              onClick={() => {
                setSlided(false);
                setSlidedInWilayah(true);
                setSlidedInMilik(false);
                setTimeout(() => {
                  setPickLokasi("");
                  setIsMilik(null);
                }, 700);
                cekData();
              }}
              text={""}
            />
          ) : null}

          {!start ? (
            <Zoom
              in={zoomedInAwal}
              style={{ transitionDelay: zoomedInAwal ? "300ms" : "50ms" }}
            >
              <div>
                <Awal
                  mulaiTaksasi={mulaiTaksasi}
                  setZoomedInAwal={setZoomedInAwal}
                  setZoomed={setZoomed}
                />
              </div>
            </Zoom>
          ) : (
            <div>
              {pickLokasi == "" ? (
                <Zoom
                  in={zoomed}
                  style={{ transitionDelay: zoomed ? "100ms" : "50ms" }}
                >
                  <div>
                    {!isMilik && (
                      <Merk
                        loadMerk={loadMerk}
                        setLoadMerk={setLoadMerk}
                        pickMerk={pickMerk}
                        pickTahun={pickTahun}
                        ambilMerk={pilihMerk}
                        ambilTahun={pilihTahun}
                        ambilVarian={pilihVarian}
                        setSlidedInVarian={setSlidedInVarian}
                        setSlidedInTahun={setSlidedInTahun}
                        setSlidedInTipe={setSlidedInTipe}
                        setSlidedInWilayah={setSlidedInWilayah}
                        tempoMerk={tempoMerk}
                        setTempoMerk={setTempoMerk}
                        setTempoTahun={setTempoTahun}
                        setToken={setToken}
                        token={token}
                        setIsTokenSame={setIsTokenSame}
                      />
                    )}

                    {pickMerk !== "" && pickTipe === "" && (
                      <Tipe
                        setJenisMotor={setJenisMotor}
                        slidedInTipe={slidedInTipe}
                        setSlidedInTipe={setSlidedInTipe}
                        setSlidedInVarian={setSlidedInVarian}
                        setSlided={setSlided}
                        ambilTipe={pilihTipe}
                        pickTipe={pickTipe}
                        pickMerk={pickMerk}
                        setTempoTahun={setTempoTahun}
                        setSearchTipe={setSearchTipe}
                        searchTipe={searchTipe}
                        setToken={setToken}
                        token={token}
                      />
                    )}
                    {pickMerk !== "" &&
                      pickTipe !== "" &&
                      pickVarian === "" && (
                        <Varian
                          slidedInVarian={slidedInVarian}
                          setSlidedInVarian={setSlidedInVarian}
                          setSlidedInTahun={setSlidedInTahun}
                          setSlided={setSlided}
                          ambilVarian={pilihVarian}
                          pickTipe={pickTipe}
                          pickMerk={pickMerk}
                          setTempoTahun={setTempoTahun}
                          setSearchTipe={setSearchTipe}
                          searchTipe={searchTipe}
                          setToken={setToken}
                          token={token}
                        />
                      )}
                    {pickMerk !== "" &&
                      pickTipe !== "" &&
                      pickVarian !== "" &&
                      pickTahun === "" && (
                        <Tahun2
                          setSlidedInTahun={setSlidedInTahun}
                          setSlidedInWilayah={setSlidedInWilayah}
                          slidedInTahun={slidedInTahun}
                          setSlided={setSlided}
                          ambilTahun={pilihTahun}
                          setIsMilik={setIsMilik}
                          pickTipe={pickTipe}
                          pickVarian={pickVarian}
                          pickMerk={pickMerk}
                          setTempoTahun={setTempoTahun}
                          setSearchTipe={setSearchTipe}
                          searchTipe={searchTipe}
                          setToken={setToken}
                          token={token}
                        />
                      )}
                    {pickMerk !== "" &&
                      pickTipe !== "" &&
                      pickVarian !== "" &&
                      pickTahun !== "" &&
                      pickLokasi === "" && (
                        <Wilayah
                          setSlidedInWilayah={setSlidedInWilayah}
                          slidedInWilayah={slidedInWilayah}
                          setSlidedInMilik={setSlidedInMilik}
                          slidedInMilik={slidedInMilik}
                          setSlided={setSlided}
                          ambilWilayah={pilihLokasi}
                          setZoomedInTaksasi={setZoomedInTaksasi}
                          pickTipe={pickTipe}
                          pickVarian={pickVarian}
                          pickTahun={pickTahun}
                          pickMerk={pickMerk}
                          setIsMilik={setIsMilik}
                          isMilik={isMilik}
                          setTempoTahun={setTempoTahun}
                          setSearchTipe={setSearchTipe}
                          searchTipe={searchTipe}
                          setToken={setToken}
                          token={token}
                        />
                      )}
                  </div>
                </Zoom>
              ) : hasilTaksasi === false ? (
                <Slide direction="up" in={zoomedInTaksasi} timeout={700}>
                  <div>
                    <Taksasi
                      pickTipe={pickTipe}
                      pickMerk={pickMerk}
                      pickTahun={pickTahun}
                      pickVarian={pickVarian}
                      pickLokasi={pickLokasi}
                      isMilik={isMilik}
                      openYoutube={openYoutube}
                      idYoutube={idYoutube}
                      setOpenYoutube={setOpenYoutube}
                      setIdYoutube={setIdYoutube}
                      hasilTaksasi={hasilTaksasi}
                      setHasilTaksasi={setHasilTaksasi}
                      setZoomedInTaksasi={setZoomedInTaksasi}
                      setPickLokasi={setPickLokasi}
                      setIsMilik={setIsMilik}
                      setSlidedInWilayah={setSlidedInWilayah}
                      idHasilTaksasi={idHasilTaksasi}
                      setIdHasilTaksasi={setIdHasilTaksasi}
                      setToken={setToken}
                      token={token}
                    />
                  </div>
                </Slide>
              ) : (
                <Zoom
                  in={zoomedInResult}
                  style={{ transitionDelay: zoomedInResult ? "100ms" : "50ms" }}
                >
                  <div>
                    <ResultTaksasi
                      idHasilTaksasi={idHasilTaksasi}
                      clearAll={clearAll}
                      setToken={setToken}
                      token={token}
                      version={version}
                    />
                  </div>
                </Zoom>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
