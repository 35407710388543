import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Stepper = ({ className, step, textProps, image, onClick }) => {
  return (
    <div className={className}>
      {step !== 0 ? (
        <div className="px-3 pt-2">
          <div
            style={{ textAlign: "left" }}
            className="d-flex align-items-center"
          >
            <ArrowBackIosIcon
              style={{ color: "#10494E", fontSize: "24px", fontWeight: 700 }}
              fontSize="small"
              onClick={onClick}
            />
          </div>
        </div>
      ) : (
        <div className=" py-3">
          <div
            style={{ textAlign: "left" }}
            className="d-flex align-items-center"
          >
          </div>
        </div>
      )}

      <div className={"paraBox px-3  pt-5"}>
        <p className={"title"}>Yuk, kenali Taksasi</p>

        <p className={"subtitle"}>{textProps}</p>
      </div>

      <img src={image} style={{height: '30vh'}} className={"img"} />
    </div>
  );
};

export default Stepper;
