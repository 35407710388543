import React, { useState, useEffect } from "react";
// import MainLayout from "../layouts/MainLayout";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, createTheme } from "@mui/material/styles";
import HomeMobile from "../interface/mobile/Home";
import HomeWeb from "../interface/web/Home";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogContentText } from "@mui/material";
import Slide from "@mui/material/Slide";
import YoutubeEmbed from "../components/YouTube";
import { useLocation } from "react-router-dom";
// import { Offline, Online } from "react-detect-offline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DialogTitle from "@mui/material/DialogTitle";
import "./Home.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Home = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 760,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [slidedInMilik, setSlidedInMilik] = useState(false);
  const [slidedInVarian, setSlidedInVarian] = useState(false);
  const [slidedInWilayah, setSlidedInWilayah] = useState(false);
  const [slidedInTipe, setSlidedInTipe] = useState(false);
  const [slided, setSlided] = useState(false);
  const [slidedInTahun, setSlidedInTahun] = useState(true);
  const [zoomed, setZoomed] = useState(true);
  const [zoomedInLokasi, setZoomedInLokasi] = useState(true);
  const [zoomedInAwal, setZoomedInAwal] = useState(true);
  const [zoomedInTaksasi, setZoomedInTaksasi] = useState(true);
  const [zoomedInResult, setZoomedInResult] = useState(true);
  const [start, setStart] = useState(false);
  const [jenisMotor, setJenisMotor] = useState();
  const [pickMerk, setPickMerk] = useState("");
  const [pickTipe, setPickTipe] = useState("");
  const [pickVarian, setPickVarian] = useState("");
  const [pickTahun, setPickTahun] = useState("");
  const [pickLokasi, setPickLokasi] = useState("");
  const [isMilik, setIsMilik] = useState(null);
  const [tempoTahun, setTempoTahun] = useState("");
  const [tempoMerk, setTempoMerk] = useState(0);
  const [searchTipe, setSearchTipe] = useState("");
  const [openYoutube, setOpenYoutube] = useState(false);
  const [isToken, setIsToken] = useState(false);
  const [isTokenSame, setIsTokenSame] = useState(false);
  const [idYoutube, setIdYoutube] = useState("");
  const [hasilTaksasi, setHasilTaksasi] = useState(false);
  const [idHasilTaksasi, setIdHasilTaksasi] = useState("");
  const [token, setToken] = useState(null);
  const [version, setVersion] = useState(null);

  let paramawal = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(paramawal.search);
    const tokennya = queryParams.get("token");
    const versionCheck = queryParams.get("version");
    if (tokennya !== null) {
      setToken(tokennya);
      setVersion(versionCheck);
    } else {
      setIsToken(true);
    }

    // console.log("token", token);
  }, []);

  const mulaiTaksasi = () => {
    setStart(true);
  };

  const pilihMerk = (param) => {
    setPickMerk(param);
    setPickTipe("");
    // setUseMerk(param)
    // console.log("merk terpilih", pickMerk);
  };
  const pilihTipe = (param) => {
    setPickTipe(param);
    // setUseMerk(param)
    // console.log("tipe terpilih", param);
  };
  const pilihVarian = (param) => {
    setPickVarian(param);
  };
  const pilihTahun = (param) => {
    setPickTahun(param);
    // setUseMerk(param)
    // console.log("tahun terpilih", param);
  };
  const pilihLokasi = (param) => {
    setPickLokasi(param);
    // setUseMerk(param)
    // console.log("lokasi terpilih", param);
  };
  const cekData = () => {
    // console.log("pick merk", pickMerk);
    // console.log("pick tipe", pickTipe);
    // console.log("pick varian", pickVarian);
    // console.log("pick tahun", pickTahun);
    // console.log("pick lokasi", pickLokasi);
  };

  const clearAll = () => {
    setIsMilik(null);
    setSlidedInMilik(true);
    setSlidedInTipe(true);
    setSlided(false);
    setSlidedInTahun(true);
    setZoomed(true);
    setZoomedInLokasi(true);
    setZoomedInAwal(true);
    setZoomedInTaksasi(true);
    setZoomedInResult(true);
    // if (type) {
    //   setStart(false);
    // }
    setJenisMotor();
    setPickMerk("");
    setPickTipe("");
    setPickVarian("");
    setPickTahun("");
    setPickLokasi("");
    setTempoTahun("");
    setTempoMerk(0);
    setSearchTipe("");
    setOpenYoutube(false);
    setIdYoutube("");
    setHasilTaksasi(false);
    setIdHasilTaksasi("");
  };

  const handleClose = (event, reason) => {
    // setOpenYoutube(false);
    if (reason && reason == "backdropClick") return;
    else setOpenYoutube(false);
  };

  const handleDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    else setIsToken(false);
  };

  const handleDialogClose2 = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    else setIsTokenSame(false);
  };
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJjbGllbnRfaWQiOjM2NzAsImNsaWVudF9wbGF0Zm9ybSI6Im1vYmlsZSIsImNsaWVudF9yb2xlIjoidXNlciIsImNsaWVudF90eXBlIjoicHJlbWl1bSIsImV4cCI6MTY3MjQ3ODU0N30.zUW_H0zr6kSj_xkbylQYID-BuWXHzMxvJihaABIz_YU";
  return (
    <div className="homeview">
      {matches ? (
        <HomeMobile
          start={start}
          pickMerk={pickMerk}
          pickTipe={pickTipe}
          pickVarian={pickVarian}
          pickTahun={pickTahun}
          pickLokasi={pickLokasi}
          isMilik={isMilik}
          slided={slided}
          slidedInVarian={slidedInVarian}
          slidedInTipe={slidedInTipe}
          slidedInTahun={slidedInTahun}
          slidedInWilayah={slidedInWilayah}
          slidedInMilik={slidedInMilik}
          jenisMotor={jenisMotor}
          setStart={setStart}
          setPickMerk={setPickMerk}
          setPickTipe={setPickTipe}
          setPickVarian={setPickVarian}
          setPickTahun={setPickTahun}
          setPickLokasi={setPickLokasi}
          setIsMilik={setIsMilik}
          setSlided={setSlided}
          setSlidedInVarian={setSlidedInVarian}
          setSlidedInTipe={setSlidedInTipe}
          setSlidedInTahun={setSlidedInTahun}
          setSlidedInWilayah={setSlidedInWilayah}
          setSlidedInMilik={setSlidedInMilik}
          zoomed={zoomed}
          setZoomed={setZoomed}
          zoomedInLokasi={zoomedInLokasi}
          setZoomedInLokasi={setZoomedInLokasi}
          zoomedInAwal={zoomedInAwal}
          setZoomedInAwal={setZoomedInAwal}
          zoomedInTaksasi={zoomedInTaksasi}
          setZoomedInTaksasi={setZoomedInTaksasi}
          zoomedInResult={zoomedInResult}
          mulaiTaksasi={mulaiTaksasi}
          pilihMerk={pilihMerk}
          pilihTipe={pilihTipe}
          pilihVarian={pilihVarian}
          pilihTahun={pilihTahun}
          pilihLokasi={pilihLokasi}
          setJenisMotor={setJenisMotor}
          cekData={cekData}
          tempoTahun={tempoTahun}
          setTempoTahun={setTempoTahun}
          tempoMerk={tempoMerk}
          setTempoMerk={setTempoMerk}
          setSearchTipe={setSearchTipe}
          searchTipe={searchTipe}
          openYoutube={openYoutube}
          idYoutube={idYoutube}
          setOpenYoutube={setOpenYoutube}
          setIdYoutube={setIdYoutube}
          hasilTaksasi={hasilTaksasi}
          setHasilTaksasi={setHasilTaksasi}
          clearAll={clearAll}
          idHasilTaksasi={idHasilTaksasi}
          setIdHasilTaksasi={setIdHasilTaksasi}
          setToken={setToken}
          token={token}
          version={version}
          setIsTokenSame={setIsTokenSame}
        />
      ) : (
        <HomeWeb />
      )}
      <Dialog
        PaperProps={{
          style: {
            width: "100%",
            position: "absolute",
            margin: "0 auto",
            background: "transparent",
            boxShadow: "none",
            WebkitBoxShadow: "none",
            MozBoxShadow: "none",
          },
        }}
        open={openYoutube}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <Online> */}
        <div style={{ backgroundColor: "black" }}>
          {openYoutube ? <YoutubeEmbed embedId={idYoutube} /> : null}
        </div>
        <center className="mb-2 mt-4" style={{ color: "white" }}>
          <div
            style={{ background: "#000", width: "30vw", borderRadius: "15px" }}
            className="py-1"
            onClick={() => {
              setOpenYoutube(false);
            }}
          >
            <HighlightOffIcon />
            <span> Keluar</span>
          </div>
        </center>
        {/* </Online> */}
        {/* <Offline>
          <div style={{ background: "black" }}>
            <DialogTitle>
              <div style={{ color: "white" }}>Tidak ada koneksi internet</div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div style={{ color: "white" }}>
                  Periksa kembali koneksi internet Sobat!, page video akan
                  otomatis reload apabila internet stabil
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Offline> */}
      </Dialog>
      <Dialog
        fullScreen
        disableEscapeKeyDown
        open={isToken}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="py-0 px-0">
          <center className="pb-4">
            <img
              style={{ position: "relative", width: "100%" }}
              src={require("../images/update-app.webp")}
            />
            <DialogContentText
              style={{
                fontFamily: "poppins",
                position: "absolute",
                top: "50%",
                left: "5%",
                right: "5%",
              }}
            >
              <span style={{ fontWeight: "700", fontSize: "25px" }}>
                Perbarui aplikasimu ke versi terbaru
              </span>
            </DialogContentText>
          </center>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        disableEscapeKeyDown
        open={isTokenSame}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="py-0 px-0 d-flex align-items-center">
          <center className="pb-4">
            <img
              style={{ width: "70%" }}
              src={require("../images/errorMerk.webp")}
            />
            <DialogContentText className="px-3 pt-5">
              <span style={{ fontWeight: "700", fontSize: "20px" }}>
                Sobat harus login ulang untuk mengakses halaman ini
              </span>
            </DialogContentText>
          </center>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Home;
