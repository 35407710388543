import React from "react";

import "./HomeWeb.css";

function Home() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          Website
          <br />
          Coming Soon
          <br/>
          <img src={require('../../images/giff.gif')}/>
        </h1>
      </div>
    </div>
  );
}

export default Home;