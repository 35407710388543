import React, { useState, useEffect } from "react";
import { Card, Grid, Box, Button, Dialog, Slide, Toolbar } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import AES256 from "aes-everywhere";
import moment from "moment";
import "moment/locale/id";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ResultImage from "../../images/resultImage.webp";
import LinkIcon from "@mui/icons-material/Link";
import ReplyIcon from "@mui/icons-material/Reply";
import updateApps from "../../images/updateApps.webp";
import CloseIcon from "@mui/icons-material/Close";
import BackToStart from "../../images/backToStart.webp";
import "./ResultTaksasi.css";
import axios from "axios";
import Gold from "../../images/Gold.webp";
import Silver from "../../images/Silver.webp";
import Bronze from "../../images/Bronze.webp";
import DialogError from "../../components/DialogError";

moment.locale("id");
const baseUrl = process.env.REACT_APP_BASE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResultTaksasi = ({
  clearAll,
  idHasilTaksasi,
  setToken,
  token,
  version,
}) => {
  const [resultTaksasi, setResultTaksasi] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogBack, setOpenDialogBack] = useState(false);
  const [dateUpdate, setDateUpdate] = useState(null);
  const [isError, setIsError] = useState(false);

  const [level, setLevel] = useState([]);
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenDialogBack(false);
  };

  useEffect(() => {
    if (resultTaksasi.length === 0) {
      getResultTaksasi();
    }
    const date = moment(resultTaksasi?.updated_at);
    const formattedDate = date.format("D MMMM YYYY");
    setDateUpdate(formattedDate);

    if (resultTaksasi?.data_taksasi?.presentase < 50) {
      setLevel([
        {
          lev: "bronze",
          img: Bronze,
        },
      ]);
    } else if (resultTaksasi?.data_taksasi?.presentase < 80) {
      setLevel([
        {
          lev: "silver",
          img: Silver,
        },
      ]);
    } else {
      setLevel([
        {
          lev: "gold",
          img: Gold,
        },
      ]);
    }
  }, [resultTaksasi]);

  const shareLink = () => {
    if (queryParams.has("android")) {
      queryParams.delete("android");
    }
    var encrypted =
      "https://simotor.id/taksasi/?id=" +
      AES256.encrypt(idHasilTaksasi.toString(), "DNA2022");

    queryParams.set("android_share_link", encrypted);
    if (version >= 118) {
      history.push({ search: queryParams.toString() });
    } else {
      setOpenDialog(true);
    }
    // decryption
    // console.log(AES256.decrypt(encrypted, "DNA2022"));
  };
  const screenShot = () => {
    if (queryParams.has("share_link")) {
      queryParams.delete("share_link");
    }
    queryParams.set("android", "share");
    if (version >= 112) {
      history.push({ search: queryParams.toString() });
    } else {
      setOpenDialog(true);
    }
  };
  // const setQueryParam = (key, value) => {
  //   queryParams.set(key, value);

  //   // Update the URL with the new query parameter
  //   if (version >= 118) {
  //     history.push({ search: queryParams.toString() });
  //   } else {
  //     setOpenDialog(true);
  //   }
  // };
  const openGooglePlayStore = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.digitalnetworkasia.simotorbeta";
  };
  const getResultTaksasi = async () => {
    var params = new URLSearchParams();

    params.append("id", idHasilTaksasi);

    var request = {
      params: params,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios
      .get(baseUrl + "/produk/v3/taksasi/detail", request)
      .then((response) => {
        // console.log("respon", response.data.data);
        setResultTaksasi(response.data.data);
        // var merk = response.data.data;
        // for (let i = 0; i < merk.length; i++) {
        //   merk[i].image = require("../../images/honda_kecil.png");
        // }
        // merk = merk.filter((item) => item.merk !== "");
        // setListMerk(merk);
        // console.log("merk", merk);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
      });
  };
  return (
    <div>
      <Dialog
        fullScreen
        sx={{
          marginTop: "65px",
        }}
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <Toolbar
          sx={{
            position: "relative",
            bgcolor: "#10494E",
            marginBottom: "-10px",
            justifyContent: "right",
          }}
        >
          <Button
            sx={{ color: "white", fontFamily: "Poppins" }}
            onClick={handleCloseDialog}
          >
            Tutup
          </Button>
        </Toolbar>
        <Card
          sx={{ height: "100vh", bgcolor: "#10494E", textAlign: "center" }}
          className="p-3"
        >
          <img
            src={updateApps}
            alt="update"
            style={{
              zIndex: 1,
            }}
          />
          <div
            className="mt-3"
            style={{
              userSelect: "none",
              color: "white",
              fontSize: "16px",
              fontWeight: 700,
              fontFamily: "Poppins",
            }}
          >
            Perbarui Aplikasi
          </div>
          <div
            style={{
              userSelect: "none",
              color: "white",
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Poppins",
            }}
            className="mt-2"
          >
            Aplikasi saat ini belum menyediakan fitur ini, update sekarang untuk
            akses fitur terbaru.
          </div>
          <Button
            className="mt-3"
            sx={{
              fontFamily: "Poppins",
              bgcolor: "#20929D",
              color: "white",
              borderRadius: "10px",
              width: "100%",
            }}
            onClick={openGooglePlayStore}
          >
            <div
              style={{
                userSelect: "none",
                fontWeight: 400,
                fontSize: "1em",
              }}
            >
              Perbarui Aplikasi
            </div>
          </Button>
        </Card>
      </Dialog>
      <Dialog
        open={openDialogBack}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <Toolbar
          sx={{
            position: "relative",
            marginBottom: "-20px",
            justifyContent: "right",
          }}
        >
          <Button
            sx={{ color: "#10494E", fontFamily: "Poppins" }}
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </Button>
        </Toolbar>
        <Card className="p-3 text-center">
          <img src={BackToStart} />
          <div
            style={{
              color: "#10494E",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            Sobat yakin ingin memulai cek Harga Pasar lagi?
          </div>
          <div className="d-flex pt-3" style={{ gap: 10 }}>
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              sx={{ width: "50%", color: "#10494E", fontFamily: "Poppins" }}
            >
              Batal
            </Button>
            <Button
              onClick={clearAll}
              variant="contained"
              sx={{ width: "50%", bgcolor: "#10494E", fontFamily: "Poppins" }}
            >
              Oke
            </Button>
          </div>
        </Card>
      </Dialog>
      {resultTaksasi.length !== 0 ? (
        <Card
          className="resultClass"
          sx={{
            height: "100vh",
            borderRadius: "0px",
            overflow: "auto",
            bgcolor: "#10494E",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              bgcolor: "#1B7A83",
              maxHeight: "50%",
            }}
          >
            <div className="px-3 py-2">
              <div
                style={{ textAlign: "left" }}
                className="d-flex align-items-center"
              >
                <ArrowBackIosIcon
                  onClick={setOpenDialogBack}
                  style={{ color: "white", fontSize: "24px", fontWeight: 700 }}
                  fontSize="small"
                />
                <div
                  style={{ fontSize: "24px", fontWeight: 700, color: "white" }}
                >
                  Hasil Taksasi
                </div>
              </div>
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <img
                alt=""
                src={ResultImage}
                style={{ alignSelf: "flex-end", maxWidth: "250px" }}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              bgcolor: "#10494E",
              flexDirection: "column",
              justifyContent: "space-between",
              textAlign: "left",
            }}
            className="px-3 py-2 mt-1"
          >
            <Box>
              <div
                className="text-center pb-2"
                style={{ fontSize: "14px", fontWeight: 500, color: "white" }}
              >
                Hasil Taksasi
              </div>
              <Card
                className=" p-3"
                sx={{
                  background:
                    level[0]?.lev === "gold"
                      ? "linear-gradient(0deg, #FD5900 0%, #FFDE00 100%)"
                      : level[0]?.lev === "silver"
                      ? "linear-gradient(0deg, #ADADAD 0%, #E6E6E6 100%)"
                      : "linear-gradient(187deg, #FBC56D 5.57%, #C5715A 93.42%)",
                  borderRadius: "16px",
                }}
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    userSelect: "none",
                    fontSize: "0.9em",
                    color: "white",
                  }}
                >
                  <img
                    src={level[0]?.img}
                    className="me-2"
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  />{" "}
                  <span
                    className="me-1"
                    style={{
                      fontWeight: "800",
                      fontSize: "16px",
                      color:
                        level[0]?.lev === "gold"
                          ? "#FFFFFF"
                          : level[0]?.lev === "silver"
                          ? "#717375"
                          : "#955543",
                    }}
                  >
                    {resultTaksasi.data_taksasi?.presentase}%
                  </span>{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      color:
                        level[0]?.lev === "gold"
                          ? "#FFFFFF"
                          : level[0]?.lev === "silver"
                          ? "#717375"
                          : "#955543",
                    }}
                  >
                    Level {level[0]?.lev}
                  </span>
                </div>
              </Card>
              <Box sx={{ display: "flex" }} className="my-3">
                <Box
                  sx={{
                    background: "#20929D",
                    borderRadius: "16px",
                    border: "2px solid white",
                    width: "100%",
                  }}
                  className="backForthAnim2 px-3 py-2 me-3"
                >
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.7em",
                      color: "white",
                    }}
                  >
                    Estimasi Harga
                  </div>
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.9em",
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    Rp.{" "}
                    {new Intl.NumberFormat("de-DE").format(
                      resultTaksasi.data_taksasi?.estimasi_harga
                    )}
                  </div>
                </Box>

                <Card
                  sx={{
                    background: "#20929D",
                    borderRadius: "16px",
                    width: "100%",
                  }}
                  className="px-3 py-2"
                >
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.7em",
                      color: "white",
                    }}
                  >
                    Merek
                  </div>
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.9em",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    {resultTaksasi.data_taksasi?.merk}
                  </div>
                </Card>
              </Box>
              <Box className="my-3 d-flex">
                <Card
                  sx={{
                    background: "#20929D",
                    borderRadius: "16px",
                    width: "100%",
                  }}
                  className="px-3 py-2 me-3"
                >
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.7em",
                      color: "white",
                    }}
                  >
                    Tipe
                  </div>
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.9em",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    {resultTaksasi.data_taksasi?.type}
                  </div>
                </Card>
                <Card
                  sx={{
                    background: "#20929D",
                    borderRadius: "16px",
                    width: "100%",
                  }}
                  className="px-3 py-2"
                >
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.7em",
                      color: "white",
                    }}
                  >
                    Varian
                  </div>
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.9em",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    {resultTaksasi.data_taksasi?.varian}
                  </div>
                </Card>
              </Box>
              <Box className="my-3 d-flex">
                <Card
                  sx={{
                    background: "#20929D",
                    borderRadius: "16px",
                    width: "30%",
                  }}
                  className="px-3 py-2 me-3"
                >
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.7em",
                      color: "white",
                    }}
                  >
                    Tahun
                  </div>
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.9em",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    {resultTaksasi.data_taksasi?.tahun}
                  </div>
                </Card>
                <Card
                  sx={{
                    background: "#20929D",
                    borderRadius: "16px",
                    width: "70%",
                  }}
                  className="px-3 py-2"
                >
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.7em",
                      color: "white",
                    }}
                  >
                    Wilayah
                  </div>
                  <div
                    style={{
                      userSelect: "none",
                      fontSize: "0.9em",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    {resultTaksasi.data_taksasi.wilayah}
                  </div>
                </Card>
              </Box>
              <div
                style={{
                  userSelect: "none",
                  textAlign: "center",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                Diperbaharui pada tanggal {dateUpdate}
              </div>
              <Box
                className="d-flex"
                sx={{
                  gap: 2,
                }}
              >
                <Button
                  onClick={() => screenShot()}
                  sx={{
                    borderRadius: "10px",
                    width: "50%",
                    color: "white",
                    fontFamily: "Poppins",
                  }}
                  className="btnShare mt-2"
                >
                  <div
                    style={{
                      userSelect: "none",
                      fontWeight: 400,
                      fontSize: "0.9em",
                    }}
                  >
                    Screenshot
                  </div>
                  <ReplyIcon
                    className="ms-1"
                    style={{
                      transform: "scaleX(-1)",
                      fontSize: "15px",
                    }}
                  />
                </Button>
                <Button
                  sx={{
                    borderRadius: "10px",
                    width: "50%",
                    color: "white",
                    fontFamily: "Poppins",
                  }}
                  className="btnShare mt-2"
                  onClick={() => shareLink()}
                >
                  <div
                    style={{
                      userSelect: "none",
                      fontWeight: 400,
                      fontSize: "0.9em",
                    }}
                  >
                    Bagikan
                  </div>
                  <LinkIcon
                    className="ms-1"
                    style={{
                      transform: "rotate(140deg)",
                      fontSize: "15px",
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      ) : null}
      <DialogError
        isOpen={resultTaksasi.length === 0 && isError ? true : false}
      />
    </div>
  );
};

export default ResultTaksasi;
